.background {
    padding: 6px;
    background-color: #d3d3d3;
}

.page{
    padding-top: 65px;
    font-family: 'Roboto';
}

.page-header{
    position: absolute;
    height: 50px;
    background-color: #ffffff;
    width: 100%;
    top: 0px;
    left: 0px;
    box-shadow: 0px 0px 3px #999;
    text-align: right;
}

.page-header > div{
    padding: 5px 40px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 400;
}

.page-canvas {
    position: relative;
    left: 50%;
    float:left;
    clear: left;
    transform: translate(-50%, 0%);
}

.page-content > h1,
.page-list > h1{
    margin: 0px;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
}

.page .page-content,
.page .page-list{
    padding: 15px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 3px #999;
    margin-top: 20px;
}

.page .page-list-content{
    margin-top: 24px;
}

.limiter{
    height: 1px;
    border-bottom: 1px solid #7F7F7F;
}

.list-item-action{
    display: flex;
    width: 100%;
    margin: 0;
}

.list-item-action > div{
    padding: 15px 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border-bottom: 1px solid #7F7F7F;
    height: 48px;
    transition-duration: 0.28s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: background-color;
}

.list-item-action:hover > div{
    background-color:rgba(var(--colorrgb), 0.3);
}

.list-empty{
    padding: 15px 15px;
    text-align: center;
}

.page-list__header{
    border-bottom: 1px solid #7F7F7F;
    padding-bottom: 5px;
    font-weight: 700;
    font-size: 12px;
    padding: 15px 8px;
}

.page-list__item{
    cursor: pointer;
}

.branding-logo{
    float: left;
    max-height: 30px;
}

.notification-success{
    border: 1px solid;
    border-color:#6AE79C;
    border-radius: 4px;
    background-color: rgba(198, 246, 217, 0.5);
    padding: 15px 15px;
    margin-top: 20px !important;
    color: #1AA251;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    position: relative;
}

.notification-warning{
    border: 1px solid;
    border-color:#FFECA1;
    border-radius: 4px;
    background-color: rgb(255, 249, 235);
    padding: 15px 15px;
    margin-top: 20px !important;
    color: #8C5800;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    position: relative;
}

.notification-success .material-symbols-outlined,
.notification-warning .material-symbols-outlined{
    position: absolute;
    top: 11px;
    right: 5px;
    cursor: pointer;
}

.notification-direct-debit{
    background-color: #1C1C1C;
    border-radius: 4px;
    padding: 25px;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
    margin-top: 24px !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
}

.notification-direct-debit span{
    font-size: 14px;
}

@media (min-width: 600px) {
    .page-canvas {
        min-width: 66.6667%;
    }
}

@media (max-width: 599px) {
    .page-canvas {
        width: 300px;
    }
}

.pageTitle {
    font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    font-size: 40px;

    margin: 24px 0px;
}

.page .defaultButton,
.defaultButton {
    border-radius: 4px;
    padding: 12px 24px;
    background-color: #1c1c1c;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    text-transform: none;
    font-family: 'Roboto';
    letter-spacing: normal;
    cursor: pointer;
}

.disabled-button {
    cursor: not-allowed;
}

.button-icon {
    margin-left: 10px;
    font-size: 14px;
}

.wide {
    width: 100%;
}

.right {
    position: relative;
    left: 100%;
    transform: translate(-100%, 0%);
}

.left {
    position: relative;
    left: 0%;
}

.center {
    position: relative;
    left: 50%;
    transform: translate(-50%);
}

.login-canvas {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 24px 24px;
    background-color: #ffffff;
    max-width: 250px;
    border-radius: 4px;
    box-shadow: 0px 0px 3px #999;
}

.login-error-message {
    display: inline-block;
    width: 100%;
    height: 20px;
    line-height: 20px;
    color: darkred;
    opacity: 0;
    padding: 5px 0 0 0;
    transition: opacity 0.3s;
}

.login-error-message.show {
    opacity: 1;
}

.direct-debit-detail {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    bottom: 0;

    transform: translate(0%, 00%);
}

.column {
    float: left;
    text-align: left;
}

.left-column {
    width: 30%;
}

.right-column {
    width: 50%;
    height: 100%;
    vertical-align: middle;
}

.wide-row {
    width: 100%;
    padding: 20px 0px 0px;
}

.row {
    padding: 3px 0px;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}