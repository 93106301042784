body{
    /* background-color: #EEF1F8; */
}

p{
    padding: 0px 10px;
}

.top-logo img{
    width: 80%;
    max-height: 63px;
    max-width: 190px;
}

.loading{
    background: url("../assets/loading-icon.svg") rgba(255, 255, 255, 0.7) no-repeat center center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.line{
    border: 2px solid rgb(231, 89, 15);
    height: 0px;
    width: 100%;
    margin-top: 30px !important;
    clear: both;
}

.summary{
    padding: 8px 8px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 7px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #a6a6a6;
    background-color: #FFF;
    margin-top: 20px;
    /* Shadow 1 */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
}

.plan{
    color: #022043;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
}

.price{
    margin-top: 3px;
    display: inline-block;
    float: right;
    color: #022043;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.5px;
    text-transform: uppercase;
}

.input-msisdn{
    display: flex;
    /* height: 52px; */
    padding: 8px 8px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 7px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #a6a6a6;
    background-color: #FFF;
    /* Shadow 1 */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
}

.input-msisdn > span{
    font-size: 12px;
}

.input-msisdn.error{
    border-color: #D32F2F;
}

.input-msisdn input,
.input-msisdn input:focus,
.input-msisdn input:focus-visible{
    border: 0px;
    width: 100%;
    outline: 0px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding: 0px;
}

.error{
    color: #D32F2F !important;
}

.note{
    color: #8C8C8C;
    font-size: 12px;
}

.form-details{
    padding: 10px 0px;
}

.form-details .note{
    padding: 8px 8px;
}

.checkout-buttons{
    margin-top: 20px;
}

.freenet-bg-color{
    background-color: rgb(9, 32, 67) !important;
}

.primary-font-color{
    color: #092043;
}

.second-color{
    background-color: #808080 !important;
}

.freenet-bg-color.freenet-logo{
    flex-grow: unset;
    width: 260px;
}

.footer-links{
    margin-top: 20px;
}

.footer-links p {
    margin: 0px 0px;
}

.footer-links a{
    color: #003471;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 220%;
    text-decoration: none;
}