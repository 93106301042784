.checkout-buttons button{
    color: #FFFFFF;
    background-color: #000000;
    padding: 10px;
    white-space: nowrap;
    border: 0px;
    cursor: pointer;
    width: 100%;
    text-transform: none;
}
.checkout-buttons button:hover{
    background-color: #3c4043;
}

.ze-button{
    background: url('../../../../public/assets/ze_pay.png') no-repeat center center;
    background-size: auto 33px;
}