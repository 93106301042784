
@media (max-width: 600px) {
    #payrails-address-selector{
        display: block !important;
    }

    #payrails-postal-code-wrapper > *{
        margin-left: 0px !important;
        width: 100% !important;
    }
}
