body {
    font-family: Roboto, Ideal Sans, system-ui, sans-serif;
}

a.dottedLink:hover {
    border-bottom: 2px hsla(0,0%,10%,.5) dotted;
}

.dottedLink {
    color: hsla(0,0%,10%,.5);
    font-weight: 500; 
    margin-right: 15px;
    text-decoration: none;
}

@media (min-width: 600px) {
    .xs-content,
    .xs-footer {
        display: none;
    }

    .xs-checkout-error {
        display: none !important;
    }

    .top-margin {
        margin-top: 80px;
    }
}

@media (max-width: 600px) {
    .lg-content,
    .lg-footer,
    .lg-checkout-error {
        display: none;
    }
    .xs-centered {
        text-align: center;
    }
}

@media screen and (max-device-width: 600px) {
    .css-o0rlmm {
        flex-basis: auto !important;
        max-width: none !important;
        margin-left: 4px !important;
    }
}

.branding-logo {
    max-width: 250px;
}

@media (max-width: 280px) {
    .branding-logo {
        max-width: 200px;
    }
}

.image-wrapper {
    position: relative;
    width: 38px;
}

.image-wrapper img {
    position: absolute;
    vertical-align: top;
    text-align: center;
}

.clickable-text {
    color: rgb(0, 116, 212);
    cursor: pointer;
    font-size: 12px;
}

.allow-text {
    font-size: .75rem;
    font-weight: 400;
    line-height: 1.45;
    text-align: left;
    color: #6d6e78;
}

#backLabel {
    display: none;
}

#backButton:hover ~ #backLabel {
    display: block;
}

#backButton:hover ~ #logo {
    display: none;
}

.lds-dual-ring {
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 34px;
    height: 34px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #000000;
    border-color: #000000 transparent #000000 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.app-loading{
    background-position: center center;
    background-repeat: no-repeat;
    background-color: rgba(255, 255, 255, 0.7);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

@keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.centerExpired {
    text-align: center;
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.lg-footer {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.payment-info{
    margin-bottom: 15px;
    word-wrap: break-word;
}